import request from '@/utils/request';
import config from "@/config";
import { filterQuery } from '@/utils/index';

export function getWaterMeterDataList(query) {
  return request({
    url: '/api/WaterMeter/GetWaterMeterList',
    method: 'get',
    params: filterQuery(query)
  });
}

export function loadWatermeterMapData() {
  return request({
    url: process.env.VUE_APP_WAREHOUSE_BACKEND + 'Definition/GetMapDefinition',
    method: 'get',
    params: {
      projectID: process.env.VUE_APP_PROJECT
    }
  });
}

export function snapPointToLine(data){
  return request({
    url: '/api/WaterMeter/SnapWatermeterToLine',
    method: 'post',
    data: data
  });
}

export function previewSnap(data){
  return request({
    url: '/api/WaterMeter/PreviewSnap',
    method: 'post',
    data: data
  });
}

export function getWaterMeterlById(waterMeterId) {
  return request({
    url: '/api/WaterMeter/GetWaterMeterlById?waterMeterId=' + waterMeterId,
    method: 'get',
  });
}

export function createWaterMeter(waterMeterData) {
  return request({
    url: '/api/WaterMeter/CreateWaterMeter',
    method: 'post',
    data: waterMeterData
  });
}

export function updateWaterMeter(waterMeterData) {
  return request({
    url: '/api/WaterMeter/Update',
    method: 'post',
    data: waterMeterData
  });
}

export function closeWaterMeter(waterMeterId) {
  return request({
    url: '/api/WaterMeter/CloseWaterMeter?waterMeterId=' + waterMeterId,
    method: 'post',
  });
}

//export function getWaterMeterDetailsByClientId(clientId) { 
//  return request({
//    url: '/api/WaterMeter/WaterMeter/GetWaterMeterDetails?waterMeterId=' + waterMeterId,
//    method: 'get',
//  });
//}

export function getAuditDataListByWaterMeterId(waterMeterId) {
  return request({
    url: '/api/WaterMeter/GetAuditDataListByWaterMeterId?waterMeterId=' + waterMeterId,
    method: 'get',
  });
}

export function getWaterMeterDataListByClientId(clientId) {
  return request({
    url: '/api/WaterMeter/getWaterMeterDataListByClientId?clientId=' + clientId,
    method: 'get',
  });
}

export function getWaterMeterDataListByClientIdExt(query) {
  return request({
    url: '/api/WaterMeter/getWaterMeterDataListByClientIdExt',
    method: 'get',
    params: filterQuery(query)
  });
}

export function getWaterMeterDataByAddressId(addressId) {
  return request({
    url: '/api/WaterMeter/GetWaterMeterDataByAddressId?addressId=' + addressId,
    method: 'get',
  });
}

export function getAddressPropertyDescriptionDropdownItemsAsKeyValuePairList(query) {
  return request({
    url: '/api/WaterMeter/GetAddressPropertyDescriptionDropdownItemsAsKeyValuePairList',
    method: 'get',
    params: filterQuery(query)
  });
}

export function getAddressPropertyStreetNameDropdownItemsAsKeyValuePairList(query) {
  return request({
    url: '/api/WaterMeter/GetAddressPropertyStreetNameDropdownItemsAsKeyValuePairList',
    method: 'get',
    params: filterQuery(query)
  });
}

export function getAddressPropertyBlockDropdownItemsAsKeyValuePairList(query) {
  return request({
    url: '/api/WaterMeter/GetAddressPropertyBlockDropdownItemsAsKeyValuePairList',
    method: 'get',
    params: filterQuery(query)
  });
}

export function getAddressPropertyEntranceDropdownItemsAsKeyValuePairList(query) {
  return request({
    url: '/api/WaterMeter/GetAddressPropertyEntranceDropdownItemsAsKeyValuePairList',
    method: 'get',
    params: filterQuery(query)
  });
}

export function getWaterMeterAddressDataDropdown(query) {
  return request({
    url: '/api/WaterMeter/GetAddressPropertyDropdownItemsAsKeyValuePairList',
    method: 'get',
    params: filterQuery(query)
  });
}

export function getAddressPropertyFloorDropdownItemsAsKeyValuePairList(query) {
  return request({
    url: '/api/WaterMeter/GetAddressPropertyFloorDropdownItemsAsKeyValuePairList',
    method: 'get',
    params: filterQuery(query)
  });
}

export function getAddressPropertyApartmentDropdownItemsAsKeyValuePairList(query) {
  return request({
    url: '/api/WaterMeter/GetAddressPropertyApartmentDropdownItemsAsKeyValuePairList',
    method: 'get',
    params: filterQuery(query)
  });
}

export function updateWaterMeterAddress(addressData) {
  return request({
    url: '/api/WaterMeter/UpdateWaterMeterAddress',
    method: 'post',
    data: addressData
  });
}
