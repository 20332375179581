<template>
  <v-container id="waterMeterList" ref="waterMeterList" fluid tag="section" class="full-height ">
    <template>
      <v-btn color="success " class="no-print print-btn" @click="printPage">
        <v-icon>mdi-printer-pos-edit-outline</v-icon></v-btn>

      <v-spacer></v-spacer>
      <filter-settings-modal ref="filterSettingsModal" />
      <gis-confirm-modal ref="gisConfirmModal" />

      <div>
        <filter-water-meter ref="waterMeterFilter" @doFilter="getData()" />

        <v-data-table id="print-table" style="
            border-bottom-right-radius: 30px;
            border-bottom-left-radius: 30px;
            margin-top: -15px;
          " ref="table" :headers="tableProps.headers" :items="tableProps.data" class="elevation-2" disable-pagination
          hide-default-footer :sort-by.sync="customSortColumn" :sort-desc.sync="customSortDesc" multi-sort
          :disable-sort="!tableProps.data || !tableProps.data.length">
          <template v-slot:body="{ items }">
            <tbody>
              <template v-if="items && items.length">
                <tr v-for="(item, idx) in items" :key="`row_${idx}`" @click="setWaterMeterSelected(item)">
                  <template v-for="(cell, idx) in tableProps.headers">
                    <td :key="'td_' + idx" v-if="cell.valueType !== 'button' &&
                      cell.valueType !== 'status' &&
                      cell.valueType !== 'textBalance' &&
                      cell.valueType !== 'checkbox'
                      " :class="cell.classItem" :style="cell.style">
                      {{ getValue(item, cell) }}
                    </td>

                    <td :key="'td_' + idx" v-if="cell.valueType === 'textBalance'" :class="cell.classItem"
                      :style="cell.style">
                      <span class="blue--text darken-4">
                        {{ getValue(item, cell) }}
                      </span>
                    </td>

                    <td :key="'td_' + idx" v-if="cell.valueType === 'status'" :class="cell.classItem +
                      ' ' +
                      (cell.active === item[cell.displayValue || cell.value]
                        ? cell.activeClass
                        : cell.notActive ===
                          item[cell.displayValue || cell.value]
                          ? cell.notActiveClass
                          : '')
                      " :style="cell.style">
                      <template v-if="cell.statusIcons">
                        <v-icon class="success--text" v-if="item[cell.displayValue || cell.value] ===
                          cell.active
                          ">mdi-check</v-icon>
                        <v-icon class="error--text" v-if="item[cell.displayValue || cell.value] ===
                          cell.notActive
                          ">mdi-close</v-icon>
                      </template>
                      <template v-else>
                        <template v-if="item[cell.displayValue || cell.value] == cell.active
                          ">
                          {{ cell.activeText }}
                        </template>
                        <template v-else-if="item[cell.displayValue || cell.value] ==
                          cell.notActive
                          ">
                          {{ cell.notActiveText }}
                        </template>
                        <template v-else>
                          {{ getValue(item, cell) }}
                        </template>
                      </template>
                    </td>

                    <td :key="'td_' + idx" v-if="cell.valueType === 'checkbox'" :class="cell.classItem"
                      :style="cell.style">
                      <v-checkbox v-model="item[cell.displayValue || cell.value]" color="primary" :value="cell.checked"
                        :false-value="cell.unchecked" hide-details class="mt-0"
                        @change="changeCheckboxValue(item)"></v-checkbox>
                    </td>

                    <td :key="'td_' + idx" v-if="cell.valueType === 'button'" :class="cell.classItem" :style="cell.style"
                      class="d-print-none">
                      <template v-for="(button, bIdx) in cell.buttons">
                        <v-btn class="button.class" v-if="checkButton(button, item)" :key="'td_button_' + bIdx"
                          :block="button.block" :style="button.style" :title="button.title"
                          @click="doAction(button.click, item)" :class="`v-size--${button.size}`"
                          :color="computedColor(button, item)" :disabled="disableBtn(button.click, item)">
                          <v-icon :class="button.label ? 'mr-2' : 'mr-0'" v-if="button.icon && button.icon.length ? true : false
                            ">{{ button.icon }}</v-icon>
                          {{ button.label }}
                        </v-btn>
                      </template>
                    </td>
                  </template>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td :colspan="tableProps.headers.length" class="text-center px-8">
                    <v-row>
                      <v-col cols="12" class="pa-7">
                        <h4 class="display-1 font-weight-medium blue-grey--text text--darken-2">
                          Няма намерени резултати
                        </h4>
                      </v-col>
                    </v-row>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-data-table>

        <div style="display: flex; justify-content: space-between">
          <div style="display: inline; padding-top: 20px; width: 150px">
            <v-select v-if="tableProps.hasPaging" :items="tableProps.pagination.perPageOptions"
              v-model="tableProps.pagination.itemsPerPage" item-text="Value" item-value="Key" placeholder="-- Избери --"
              label="Редове на страница" :color="tableProps.color" @change="changeRowsPerPage()">
            </v-select>
          </div>
          <div style="display: inline">
            <v-pagination v-if="tableProps.hasPaging" v-model="tableProps.pagination.page" :color="tableProps.props.color"
              :length="Math.ceil(
                tableProps.pagination.total /
                tableProps.pagination.itemsPerPage
              )
                " :total-visible="10" @input="changedPagination()" class="d-print-none"></v-pagination>
          </div>
          <div style="display: inline; padding-top: 30px">
            <span>Общо:
              <strong>{{ tableProps.pagination.total }}</strong> записа.</span>
          </div>
        </div>
      </div>
      <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition" style="height: 90%; width: 90%"
        scrollable>
        <v-card>
          <v-toolbar flat dark color="primary" floating:true>
            <v-toolbar-items style="margin-right: 10px; margin-top: 15px;">
              <div>


                <v-btn style="z-index: 100;" id="" class="mr-3  "  :class="{'svo-menu-btn': true, 'is-drawer-open': sidebar}" text small color="#36a498 !important"
                  @click="sidebar = !sidebar">
                  <span class="logo-normal">
                    <v-img class="cnt-logo" width="20" position="center center"
                      :src="require(`@/assets/menu-burger.svg`)"></v-img>
                  </span>

                </v-btn>
              </div>
              <v-btn class="unactiveGradient" icon style="border-radius: 3px; width: 50px; height: 48px !important;"
                @click="onCloseDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>

            </v-toolbar-items>
            <v-btn variant="outlined" text class="successGradient" small @click="snapAddressToSvo"
              v-show="selectedData.watermeterID > -1 && selectedData.svoID > -1" style="margin-right: 10px">
              Запази
            </v-btn>
            <v-btn variant="outlined" text class="neutralGradient" small @click="previewSnap"
              v-show="selectedData.watermeterID > -1 && selectedData.svoID > -1">
              Преглед
            </v-btn>
            <v-spacer></v-spacer>
            <span v-if="selectedData.svoID === -1">Избери СВО:</span>
            <span v-if="selectedData.svoID !== -1">
              СВО ИД : {{ CalculatedSvoID(selectedData.svoID) }}
            </span>
            <v-spacer></v-spacer>
            <span>{{ dialogTitle }}</span>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-navigation-drawer v-model="sidebar" app id="navigation-drawer-svo">
            <!-- Your sidebar content here -->
            <v-simple-table
            dark>
              <template v-slot:default  id="info-table" dark>
                <tbody >
                  <tr v-for="(prop, i,key) in Object.keys(asset.properties)" :key="i">
                    <template
                      v-if="asset.properties[prop] && prop !== 'valve_state_json' && prop !== 'clustered' && prop !== 'sqrt_point_count' && prop !== 'point_count'">
                      <td>
                       {{ tag_refs[prop] || `${prop}(липсва превод)` }}
                       {{ asset.properties[key] }}
                      </td>
                      <td>{{ asset.properties[prop] }}</td>
                    </template>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>


          </v-navigation-drawer>
          <mapbox :mapData="mapData" :currentWatermeter="currentWatermeter" :selectedData="selectedData" @setInfoData="setInfoData" ref="mapbox" />
        </v-card>
      </v-dialog>
    </template>
  </v-container>
</template>


<script>
import { getWaterMeterDataList } from "@/api/water_meters";
import { loadWatermeterMapData, snapPointToLine, previewSnap, loadAllAttributes } from "@/api/water_meters";

import {
  gisConfirmModal,
  filterSettingsModal,
  filterWaterMeter,
  mapbox
} from "@/components";

import moment from "moment";

import {
  formatDateTime,
  formatDateTimeSeconds,
  getArrayField,
  ISODateString,
  roundNumber,
} from "@/utils";

export default {
  name: "watermeterList",
  components: {
    gisConfirmModal,
    filterSettingsModal,
    filterWaterMeter,
    mapbox
  },
  data() {
    return {

      tableProps: {
        headers: [
          {
            text: "Водомер No.",
            value: "waterMeterFabricNo",
            valueType: "text",
            class: "successHeader",
          },
          {
            text: "Дата последен ремонт",
            value: "lastDateOfRepair",
            valueType: "date",
            class: "successHeader",
          },
          {
            text: "Дата последно засичане",
            value: "lastDateOfCheck",
            valueType: "date",
            class: "successHeader",
          },
          {
            text: "Дата следващо засичане",
            value: "nextDateOfCheck",
            valueType: "date",
            class: "successHeader",
          },
          {
            text: "Общ / Индив.",
            value: "waterMeterOwnershipType.nameBg",
            valueType: "object",
            class: "successHeader",
          },
          {
            text: "Показание",
            value: "checkValue",
            valueType: "text",
            class: "successHeader",
          },

          {
            text: "Дата на откриване партида",
            value: "createdOn",
            valueType: "dateTime",
            class: "successHeader",
          },
          {
            text: "Дата на последна промяна",
            value: "modifiedOn",
            valueType: "dateTime",
            class: "successHeader",
          },

          {
            text: "Клиентски номер",
            value: "client.clientNumber",
            valueType: "object",
            class: "successHeader",
          },
          {
            text: "Име на клиент",
            value: "client.name",
            valueType: "object",
            class: "successHeader",
          },
          {
            text: "Адрес",
            value: "address.addressDescription",
            valueType: "object",
            class: "successHeader",
          },
          {
            text: "Карнет No. / страница за адрес",
            value: ["address.carnet.carnetCode", "address.carnet.page"],
            separator: " / ",
            valueType: "valueArrayOfObjects",
            sortable: false,
            class: "successHeader",
          },
          {
            sortable: false,
            text: "",
            value: "actions",
            valueType: "button",
            class: "text-center cols cols-2 successHeader",
            style: "width:100px; padding: 10px;",
            buttons: [
              {
                label: "",
                title: "Преглед",
                class: "successGradient",
                icon: "mdi-text-box-search-outline",
                style: "width:100px; height: 30px;",
                click: "previewWaterMeter",
                id: "waterMeterDetailsPreview",
              },
              {
                label: "",
                title: "Избери СВО",
                class: "errorGradient",
                icon: "mdi-map",
                style: "width:100px; height: 30px;",
                click: "previewOnMap",
                id: "waterMeterDetailsPreview",
                hasConditionalColor: true,
                if: {
                  type: 'equal',
                  value: 2,
                  item: 'waterMeterOwnershipTypeId'
                }
              },
            ],
          },
        ],
        data: [],
        filters: {},
        hasPaging: true,
        noms: {
          statuses: [],
          priorities: [],
          users: [],
        },
        pagination: {
          itemsPerPage: 10,
          page: 1,
          perPageOptions: [5, 10, 25, 50, 100],
          total: 0,
        },

        props: {
          id: {
            type: String,
            default: "",
          },
          printAll: false,
          color: "primary",
        },
        sortColumn: [],
        sortDescending: [],
      },
      asset:{},
      tag_refs:{
    "osm_id": "ИД",
    "aq_system": "система ",
    "aq_category": "категория",
    "aq_type": "вид на обекта",
    "description": "описание",
    "aq_diameter": "дължина (калкулирана)",
    "aq_material": "материал",
    "name": "Ф 150",
    "enabled": 1,
    "aq_length": 2292.26
},
      sidebar: false,
      currentClient: null,
      sortBy: [],
      sortDesc: [],
      waterMeterSelectedId: null,
      users: [],
      dialog: false,
      dialogTitle: "",
      mapData: [],
      currentWatermeter: {},
      selectedData: {
        watermeterID: -1,
        svoID: -1,
        markerLng: -1,
        markerLat: -1,
        source: "",
        projectId: process.env.VUE_APP_PROJECT
      },
    };
  },

  watch: {
    selectedData: {
      deep: true,
      async handler(value) {
        if (value.svoID) {
          this.getAllAttributes()
        }
      }
    }
  },

  computed: {
    customSortColumn: {
      get: function () {
        return this.tableProps.sortColumn;
      },
      set: function (value) {
        this.tableProps.sortColumn = value;
        this.$emit("update:sortBy", value);
        this.$forceUpdate();
      },
    },
    customSortDesc: {
      get: function () {
        return this.tableProps.sortDesc;
      },
      set: function (value) {
        this.tableProps.sortDescending = value;
        this.$emit("update:sortDesc", value);

        this.getWaterMeters();
        this.$forceUpdate();
      },
    }
  },

  mounted() {
    this.getData();
    loadWatermeterMapData().then((res) => {
      this.mapData = res;
    });
  },

  created() {
    this.tableProps.sortColumn = this.sortBy;
    this.tableProps.sortDescending = this.sortDesc;
  },

  methods: {
    setInfoData(obj){
      this.asset = obj
      
    },
    setDrawer(val) {
      this.$store.commit('app/SET_DRAWER', val)
    },
    //   printPage() {
    //   const originalItemsPerPage = this.tableProps.pagination.itemsPerPage;

    //   this.tableProps.pagination.itemsPerPage = 100;

    //   this.getWaterMeters();

    //   setTimeout(() => {
    //     window.print();

    //     const revertAfterPrint = () => {
    //       this.tableProps.pagination.itemsPerPage = originalItemsPerPage;
    //       this.getWaterMeters(); 
    //       window.removeEventListener('afterprint', revertAfterPrint);
    //     };
    //     window.addEventListener('afterprint', revertAfterPrint);
    //   }, 10);
    // },
    async printPage() {
      const originalItemsPerPage = this.tableProps.pagination.itemsPerPage;
      this.tableProps.pagination.itemsPerPage = 100;

      await this.getWaterMeters();

      window.print();

      const revertAfterPrint = () => {
        this.tableProps.pagination.itemsPerPage = originalItemsPerPage;
        this.getWaterMeters();
        window.removeEventListener('afterprint', revertAfterPrint);
      };
      window.addEventListener('afterprint', revertAfterPrint);
    },

    async getWaterMeters() {
      let query = Object.assign({}, this.tableProps.filters);
      query.userId = this.currentClient;

      if (!query.page) query.page = this.tableProps.pagination.page;
      query.itemsPerPage = this.tableProps.pagination.itemsPerPage;
      query.sortBy = this.tableProps.sortColumn;
      query.sortDesc = this.tableProps.sortDescending;

      
      return getWaterMeterDataList(query).then((data) => {
        this.tableProps.data = data.items;
        this.tableProps.pagination.total = data.totalCount;
        this.tableProps.pagination.page = query.page;
        this.$forceUpdate();

      });
     
    },
    getAllAttributes() {
      const src = 'src_lines_w'
      loadAllAttributes(this.selectedData.svoID, src).then((res) => {
        if (res.data.length === 0) {
          return;
        }

        let _id = Object.keys(res.data[0]).find((x) => x === "osm_id") || "";

        if (!_id) {
          Object.keys(res.data[0])
            .filter((x) => x.includes("id") && Number(res.data[0][x]))
            .forEach((x) => {
              _id = x;
              return;
            });
        }
        res.data.forEach((x) => {
          const obj = this.container[src].find(
            (p) => p.props.osm_id === x[_id] || p.props.id === x[_id]
          );
          if (obj) Object.assign(obj.props, x);
        });
        this.$forceUpdate();
      });
    },

    getData() {
      this.tableProps.filters = this.$refs.waterMeterFilter.filters;
      this.getWaterMeters(this.tableProps.filters);
    },

    previewWaterMeter(waterMeterId) {
      if (waterMeterId > 0) {
        this.$router.push({ path: `/water_meters/preview/${waterMeterId}` });
        this.$forceUpdate();
      }
    },
    changeRowsPerPage() {
      if (
        this.tableProps.pagination.total > 0 &&
        this.data &&
        this.tableProps.pagination.page >=
        Math.ceil(
          this.tableProps.pagination.total /
          this.tableProps.pagination.itemsPerPage
        )
      ) {
        this.tableProps.pagination.page = Math.ceil(
          this.tableProps.pagination.total /
          this.tableProps.pagination.itemsPerPage
        );
      }

      this.getData();
    },

    checkButton(button, item) {

      let ifValue = true;
      if (button.ifValueObjectExists) {
        let props = button.ifValueObjectExists.split(".");
        if (item[props[0]]) {
          let value = props.reduce((a, v) => a[v], item);

          if (!value) ifValue = false;
        }
      }
      return (
        (button.if
          ? button.if.type
            ? button.if.type === "equal"
              ? item[button.if.item] === button.if.value
              : false
            : item[button.if.item] !== button.if.value
          : true) && ifValue
      );
    },

    getValue(item, options) {
      let template = "";
      let keys = [];
      let props = [];
      let prop = options.displayValue || options.value;
      let result;
      let result1;
      let itemData;

      if (options.value == "closed") {
        return item.closed == false ? "Не" : "Да";
      }

      switch (options.valueType) {
        case "decimal":
          return roundNumber(item[prop || options.value], 16, 16, 8);
        case "text":
          return item[prop] || item[prop] === 0 ? item[prop] : " — ";
        case "textGL":
          return item[prop] || "GL";
        case "textBalance":
          // result = (item[options.value] + '').split('.')[1];
          // if(result && result.length <= 1 || (item[options.value] + '').length === 1){
          return roundNumber(item[prop || options.value], 2);
        // }
        // return item[options.value];
        case "price":
          return roundNumber(item[prop || options.value], 2);
        case "priceAccounting":
          var num = item[prop || options.value];
          if (typeof num === "number") num = num + "";

          var value = Number(num);

          var res = num.split(".");
          if (res.length == 1 || res[1].length < 3)
            value = roundNumber(value, 2);

          return value;
        case "array":
          // eslint-disable-next-line no-case-declarations
          let val = getArrayField(item, prop, options.comma);
          if (val && val !== "null") {
            return val;
          }
          return "";
        //return val != null ? val : '';
        case "date":
          if (item[prop]) {
            // eslint-disable-next-line no-case-declarations
            let d = moment();

            if (
              (typeof item[prop] === "number" &&
                item[prop].toString().length === 8) ||
              (typeof item[prop] === "string" && item[prop].length === 8)
            ) {
              d = moment(item[prop], "DDMMYYYY");
              if (!d.isValid()) d = moment(item[prop], "YYYYMMDD");
            } else if (
              (typeof item[prop] === "number" &&
                item[prop].toString().length === 14) ||
              (typeof item[prop] === "string" && item[prop].length === 14)
            ) {
              d = moment(item[prop], "YYYYMMDDHHmmss");
            } else {
              d = moment(item[prop]);
            }
            if (d.isValid()) {
              return ISODateString(d.toISOString());
            }
          }

          return "—";
        case "dateTime":
          if (item[prop]) {
            // eslint-disable-next-line no-case-declarations
            let d = moment(item[prop]);
            if (d.isValid()) {
              return formatDateTime(item[prop]);
            }
          }

          return "—";
        case "dateTimeSeconds":
          if (item[prop]) {
            // eslint-disable-next-line no-case-declarations
            let d = moment(item[prop]);
            if (d.isValid()) {
              return formatDateTimeSeconds(item[prop]);
            }
          }

          return "—";
        case "firstElement":
          props = prop.split(".");
          return item[props[0]] ? item[props[0]][0][props[1]] : "";
        case "firstElementObject":
          result = "";
          keys = prop.split(".");
          var firstChildren = item[keys[0]][0];
          keys.shift();
          if (firstChildren) {
            if (firstChildren[keys[0]]) {
              result = keys.reduce((a, v) => a[v], firstChildren);
            }
          }
          return result ? result : "";
        case "object":
          keys = prop.split(".");
          // eslint-disable-next-line no-case-declarations
          result = "";
          try {
            if (item[keys[0]]) {
              result = keys.reduce((a, v) => a[v], item);
            }
            return result ? result : " — ";
          } catch (e) {
            return " — ";
          }
        case "objectDate":
          keys = prop.split(".");

          // eslint-disable-next-line no-case-declarations
          let date = keys.reduce((a, v) => a[v], item);

          if (date) {
            // eslint-disable-next-line no-case-declarations
            let d;
            if (typeof date === "number" && date.toString().length === 8) {
              d = moment(date, "YYYYMMDD");
            } else {
              d = moment(date);
            }

            if (d.isValid()) {
              return ISODateString(d.toISOString());
            }
          }

          return " — ";
        case "objectDateTime":
          keys = prop.split(".");

          // eslint-disable-next-line no-case-declarations
          let dateTimeObject = keys.reduce(
            (a, v) => a[v] != null && a[v],
            item
          );

          if (dateTimeObject) {
            return formatDateTime(dateTimeObject);
          }

          return " — ";
        case "objectPrice":
          keys = prop.split(".");
          // eslint-disable-next-line no-case-declarations
          result = "";
          try {
            if (item[keys[0]]) {
              result = keys.reduce((a, v) => a[v], item);
            }
            return result ? roundNumber(result, 2) : " — ";
          } catch (e) {
            return " — ";
          }
        case "twoObjectsPrice":
          result = "";
          try {
            for (let obj of prop) {
              keys = obj.split(".");
              if (item[keys[0]]) {
                if (result.length) {
                  let val = keys.reduce((a, v) => a[v], item);
                  result += val ? " " + val : "";
                } else {
                  result += roundNumber(
                    keys.reduce((a, v) => a[v], item),
                    2
                  );
                }
              }
            }

            return result ? result : " — ";
          } catch (e) {
            return " — ";
          }
        case "valueOrValue":
          keys = prop.split(".");

          try {
            if (item[keys[0]]) {
              return item[keys[0]];
            }

            return item[keys[1]] || " — ";
          } catch (e) {
            return " — ";
          }
        case "valueOrValueObject":
          keys = prop[0].split(".");
          try {
            result = "";
            if (item[keys[0]]) {
              result = keys.reduce((a, v) => a[v], item);
            }

            if (result) {
              return result;
            }

            // checking next value
            keys = prop[1].split(".");

            result = "";
            if (item[keys[0]]) {
              result = keys.reduce((a, v) => a[v], item);
            }

            return result ? result : " — ";
          } catch (e) {
            return " — ";
          }
        case "valueArrayOfObjects":
          result = "";
          for (let i = 0; i < prop.length; i++) {
            if (prop[i].includes(".")) {
              keys = prop[i].split(".");
              result1 = keys.reduce((a, v) => a[v], item);
              if (result.length) result += options.separator || " ";
              result += result1;
            }
          }
          return result;
        case "valueOrValueArray":
          result = "";
          for (let i = 0; i < prop.length; i++) {
            if (prop[i].includes(".")) {
              keys = prop[i].split(".");
            } else {
              keys = [prop[i]];
            }

            for (let k = 0; k < keys.length; k++) {
              if (item[keys[k]]) {
                if (result.length) result += options.separator || " ";
                result += item[keys[k]];
              }
            }
          }
          return result;
        case "button":
          for (let i = 0; i < options.buttons.length; i++) {
            template += ``;
          }
          return template;
        case "sort":
          itemData = Object.assign([], item[options["sortBy"]]);

          if (options["sortType"] === "date") {
            itemData.sort(function (x, y) {
              if (options["sortDesc"]) {
                return (
                  new Date(y[options["sortValue"]]).getTime() -
                  new Date(x[options["sortValue"]]).getTime()
                );
              } else {
                return (
                  new Date(x[options["sortValue"]]).getTime() -
                  new Date(y[options["sortValue"]]).getTime()
                );
              }
            });
          }

          if (prop.includes(".")) {
            keys = prop.split(".");
            result = "";
            if (itemData[0][keys[0]]) {
              result = keys.reduce((a, v) => a[v], itemData[0]);
            }
            return result ? result : " — ";
          }

          return itemData[0][prop];
        case "status":
          if (item[prop] != null) {
            return item[prop].toString() || " - ";
          }
          return " - ";
        case "translateValue":
          result = " - ";
          for (let obj of options.translateValues) {
            if (obj.value === item[prop]) {
              result = obj.label;
            }
          }
          return result;
      }
    },
    doAction(action, data) {
      if (action === "previewWaterMeter") this.previewWaterMeter(data.id);
      if (action === "previewOnMap") this.previewOnMap(data);
    },
    disableBtn(action, data) {
      if (action === "previewOnMap") {
        if (!data.address.longitude && !data.address.latitude) {
          return true;
        }
        return false;
      }
    },
    changedPagination() {
      this.getData();
    },
    changeCheckboxValue(item) {
      this.$emit("changeCheckbox", item);
    },
    setWaterMeterSelected(waterMeterSelected) {
      this.waterMeterSelected = waterMeterSelected.id;
    },
    CalculatedSvoID(svoID) {
      if (svoID > 10000000) {
        svoID -= 10000000
        return svoID
      }
      return svoID
    },
    computedColor(btn, item) {
      ///Use this function for conditional coloring
      if (btn.hasConditionalColor) {
        if (btn.click === 'previewOnMap') {
          if (item.svoId) return "successGradient";
          return "errorGradient";
        }
      }
      return btn.class;
    },
    previewOnMap(data) {
      this.currentWatermeter = data;
      this.dialog = true;
      this.dialogTitle = data.address.addressDescription;
    },
    snapAddressToSvo() {
      snapPointToLine(this.selectedData)
        .then((res) => {
          if (res.type === 1) {
            this.previewWaterMeter(this.selectedData.watermeterID);
            this.$snotify.success("Успешна промяна на координати на водомер");
            this.getData();
          } else if (res.type === 0) {
            this.$snotify.info(res.message);
          }
          else {
            this.$snotify.error("Грешка при промяна на координати на водомер");
          }
        })
        .finally(() => {
          this.onCloseDialog();
        });
    },
    previewSnap() {
      previewSnap(this.selectedData)
        .then(res => {
          const src = this.$refs.mapbox.map.getSource('preview_src');
          src.setData({ type: "FeatureCollection", features: [res] });
        });
    },
    onCloseDialog() {
      this.dialog = false;
      this.$refs.mapbox.map.getStyle().layers.forEach(l => {
        if (l['source-layer']) {
          this.$refs.mapbox.map.removeFeatureState({
            source: l.source,
            sourceLayer: l['source-layer'],
            id: this.selectedData.svoID
          });
        }
      });

      this.selectedData.svoID = -1;
      this.selectedData.watermeterID = -1
    }
  },
};
</script>
<style >
.print-btn {
  margin-left: 90%;

}

@media print {
  body * {
    visibility: hidden;
  }

  nav#navigation-drawer-svo {
    height: 80% !important;
    margin-top: 70px;
  }

  #print-table,
  #print-table * {
    visibility: visible;
  }

  #print-table {
    position: absolute;
    left: 0;
    top: 0;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-top: 0px;
    width: 200%;
    zoom: 50%;
    transform-origin: top left;
    white-space: normal;
    word-wrap: break-word;
  }

  .no-print {
    display: none !important;
  }
 

}
</style>